import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter01 from '../common/src/assets/image/charity/specter/kapitola_01_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Prvá kapitola: Diablove stratégie na zničenie ľudstva | Ako duch komunizmu vládne nášmu svetu"
           description="Prvá kapitola: Diablove stratégie na zničenie ľudstva"
           image={imageChapter01}/>
      <h1>Prvá kapitola: Diablove stratégie na zničenie ľudstva</h1>
      <h1>ÚVOD</h1>
      <p>Duch komunizmu sa už po celé stáročia snaží skaziť a zničiť ľudstvo. Začalo to duchovným pokrivením človeka,
        oddelením ho od jeho božského pôvodu. Duch komunizmu takto viedol ľudí vo svete, aby opustili svoje tisícročné
        kultúrne tradície, ktoré Nebesá starostlivo usporiadali ako pravé štandardy pre ľudskú existenciu.</p>
      <p>Bez ohľadu na svoje starodávne dedičstvo sa celá ľudská spoločnosť rúti dole bezprecedentným tempom. Medzitým
        zástupcovia ducha komunizmu na zemi využili tento spoločenský zmätok, aby pretlačili svoje hanebné agendy,
        maskujúc ich heslami ako „oslobodenie” a „pokrok”.</p>
      <p>Za posledných viac než 200 rokov ovplyvnil duch komunizmu množstvo spoločenských udalostí i historický vývoj.
        Jeho démonický vplyv nadobúda nespočetné a zdanlivo protichodné formy, od zjavnej brutality komunistickej vlády
        na Východe až po postupné rozvracanie západnej politiky, kultúry a&nbsp;hlavného prúdu spoločnosti.</p>
      <h2>1. Poškodzovanie ľudskej mysle</h2>
      <p>V dnešnom svete sú kritériá pre rozlišovanie dobra a zla prevrátené. Spravodlivosť sa prehlásila za zlo a zlo
        za súcit. Zlovestné koncepty sú maskované pod rúškom „vedy“ a zločinecká logika je maskovaná pod heslom
        „sociálna spravodlivosť”. „Politická korektnosť” sa používa k zavedeniu kontroly nad myslením ľudí a „hodnotová
        neutralita” sa šíri, aby sa dosiahlo, že sa ľudia stanú necitliví voči brutálnym zverstvám.</p>
      <p>Človek bol stvorený Bohom a veriaci ľudia sú pod božou ochranou. Preto prvým a hlavným cieľom ducha komunizmu
        je prerušiť spojenie medzi človekom a Bohom.</p>
      <p>Socialistická hymna, <em>„Internacionála”</em>, prehlasuje, že žiadny Stvoriteľ nikdy nebol. V roku 1850
        nemecký materialistický filozof Ludwig Feuerbach vyhlásil, že Boh je len projekciou vnútornej podstaty človeka.
        Ale tradičná ľudská morálka, kultúra, spoločnosť a racionálne uvažovanie všetky pochádzajú od Boha. V búrlivom
        prúde histórie bola viera v Boha ako silné kotviace lano, ktoré zabraňovalo tomu, aby sa ľudstvo stratilo v jej
        vlnách.</p>
      <p>Ateizmus láka arogantných ľudí, aby sa hrali na Boha a pokúsili sa ovládať osudy iných ľudí i celej
        spoločnosti. Vodcovia komunistických hnutí si zvykli zbožšťovať sami seba. Keď sa zamýšľal nad krviprelievaním
        francúzskej revolúcie, britský filozof Edmund Burke vyhlásil: „Keď sa ľudia hrajú na Bohov, správajú sa ako
        diabli.”</p>
      <p>Koncept materializmu je úzko spätý s ateizmom, ktorý popiera existenciu duše. Materializmus zapustil korene
        počas priemyselnej revolúcie, kedy rýchly pokrok vo vede, technológii a výrobe podporil kult empirizmu a
        ateizmu. Ľudia stratili vieru v božské zázraky a odmietli Božie prikázania. Jadrom marxizmu a iných radikálnych
        ideológií je koncept dialektického materializmu. V&nbsp;nedávnej histórii bola idealistická dialektika prvýkrát
        formulovaná nemeckým filozofom Georgom Hegelom ako všeobecný súbor zásad pre logické myslenie. Marxizmus potom
        absorboval vybrané aspekty Hegelovej práce, pričom zveličil povahu dialektického konfliktu.</p>
      <p>V rukách ducha komunizmu slúžia materializmus a ateizmus ako démonické zbrane na zvrhnutie duchovnej viery
        človeka, podkopanie ľudskej morálky a na zničenie tradičnej kultúry. Materializmus a ateizmus vytvorili základ
        pre celý rad deformovaných intelektuálnych predsudkov. Úpadok filozofie šiel ruka v ruke s úpadkom vedy. Kult
        „vedeckej racionality” nahradil normálne ľudské uvažovanie a stal sa typom svetského náboženstva, ktoré je
        používané na potláčanie viery a popieranie morálky, čím posilňuje ateistický svetonázor.</p>
      <p>Súčasná vedecká komunita odmieta všetky javy, ktoré nedokáže vysvetliť alebo overiť svojimi metódami, a
        označuje ich ako povery a pseudovedu, alebo ich úplne ignoruje. S cieľom ovládnuť akademické myslenie a
        vzdelávací systém zaplavuje tieto oblasti ateistickými teóriami ako je darwinizmus. Darwinova teória evolúcie,
        ktorá je plná nedostatkov, bola široko prijatá ako nástroj na prerušenie spojenia medzi človekom a Bohom.
        Prirovnáva človeka k zvieratám a podkopáva tak jeho sebaúctu, ako aj jeho úctu k Božiemu stvoreniu. Dvadsiate
        storočie bolo svedkom toho, ako teória evolúcie postupne ovládla oblasti výskumu a vzdelávania. Dnes sú tí,
        ktorí veria v náuku o stvorení, zosmiešňovaní. Ateizmus a materializmus okrem vplyvu na prírodné vedy spôsobili
        vznik mnohých filozofických a ideologických trendov zakorenených v koncepte boja. Teória evolúcie v súčasnosti
        nielenže dominuje v oblasti biológie, ale má vplyv aj na spoločenské vedy. Z pôvodnej Darwinovej teórie vzišiel
        zhubný koncept sociálneho darwinizmu, ktorého koncepty „prírodného výberu” a „prežitia najsilnejších” obmedzili
        medzinárodné spoločenstvo na džungľu plnú barbarského boja medzi národmi.</p>
      <p>Démonická filozofia boja prenikla aj do oblasti jazyka. Definície a nuansy slov boli prekrútené, aby
        zodpovedali ateistickému a materialistickému mysleniu. V dystopickom románe britského spisovateľa George Orwella
        1984 je Newspeak umelým jazykom vytvoreným na posilnenie kontroly strany nad ľuďmi. Orwellove vízie sa v mnohých
        ohľadoch stali skutočnosťou. „Sloboda” bola prekrútená, dostala význam extrémneho stavu neohraničeného morálkou,
        zákonom alebo tradíciou. Frázy ako „všetci ľudia sú stvorení rovnako” a „všetci ľudia sú si rovní pred zákonom”
        boli prekrútené, aby znamenali absolútne rovnostárstvo. „Tolerancia” bola prekrútená a po novom znamenala
        toleranciu všetkých druhov pokrivených myšlienok a správania. Racionálne myslenie sa stalo nástrojom úzkoprsej
        empirickej vedy. V úsilí o dosiahnutie rovnosti výsledku bola spravodlivosť pretvorená na „sociálnu
        spravodlivosť”.</p>
      <p>Cieľom komunizmu nie je vyriešiť problémy, ale skôr, ako raz povedal čínsky republikánsky vodca Čankajšek,
        „rozšíriť globálne rozpory v čo najväčšej miere a spôsobiť, že medziľudské boje budú pokračovať navždy”.</p>
      <p>Ako je vidieť na nespočetných príkladoch z nedávnej histórie, komunizmus podnecuje nenávisť medzi ľuďmi,
        vytvára a eskaluje konflikty, a nakoniec sa chopí moci prostredníctvom násilnej revolúcie alebo trikov a
        podvodov. V&nbsp;každom prípade „oslobodenie” sľubované revolucionármi vedie k vymývaniu mozgov, zabíjaniu a
        tyranii.</p>
      <h2>2. Rozvracanie tradičnej kultúry</h2>
      <p>Tradičná ľudská kultúra bola ľuďom zverená Bohmi. Spolu so zachovaním normálneho fungovania ľudskej spoločnosti
        je najdôležitejšou úlohou Bohmi odovzdanej kultúry to, aby poskytla človeku prostriedok k pochopeniu božského
        Zákona, ktorý bude vyučovaný v záverečnej epoche, aby tak ľudia mohli byť zachránení pred zničením.</p>
      <p>Kultúra inšpirovaná Bohmi poskytuje silnú morálnu ochranu pred zlom. Cieľom ducha komunizmu je zničiť ľudskú
        kultúru tak, že obráti ľudí proti ich vlastným tradíciám.</p>
      <p>Šírenie ateizmu a materializmu oslabilo náboženské korene civilizácie, čo viedlo k vzniku nových ideologických
        hnutí založených na boji. Vo svetskej spoločnosti reprezentanti ducha komunizmu podkopali tradičné vzdelávanie,
        vytvorili degenerované moderné umenie, propagovali sexuálnu promiskuitu a pornografiu, a propagovali užívanie
        drog. Hriešne a rebelské sa oslavuje ako niečo oslobodzujúce a expresívne.</p>
      <p>Po tisíce rokov zohrávalo tradičné vzdelávanie kľúčovú úlohu pri rozvíjaní a odovzdávaní veľkolepej kultúry
        ľudstva. Študenti sa učili pracovať na zvládnutí profesionálnych, umeleckých alebo akademických zručností a ako
        byť dobrými ľuďmi a občanmi.</p>
      <p>Západné krajiny začali v 19. storočí zavádzať bezplatné verejné vzdelávanie. Do učebných osnov začali s
        príchodom 20. storočia postupne presakovať ateistické a netradičné myšlienky, podporované ľavicovými
        pedagogickými expertmi, ktorí sa infiltrovali do akademickej obce a mali rozhodujúci vplyv na vzdelávanie.
        Teória evolúcie sa stala vyžadovaným učením, zatiaľ čo politická korektnosť sa v spoločenských vedách stala
        štandardom. Učebnice boli postupne naplnené ateizmom, materializmom a triednym bojom. Tradičná kultúra,
        reprezentovaná veľkými literárnymi klasikmi, bola v rozpore s démonickým ideologickým prúdom. Klasici tak boli
        postupne marginalizovaní alebo reinterpretovaní podľa modernej sociálnej teórie. Šikovní a nadaní študenti tak
        zostali bez hlbokého porozumenia múdrosti obsiahnutej v najdôležitejších literárnych dielach ľudstva.</p>
      <p>Kreativita a zvedavosť študentov vychádza nazmar štúdiom bezvýznamných tém, pričom sú im odopreté znalosti
        hlavných zásad života a práce. Akademické štandardy boli postupne znížené. Dlhé hodiny výučby v triede oddelili
        deti od rodičov a rodinného prostredia, pričom spôsobili nepretržité vystavenie detí degenerovanému
        vzdelávaciemu systému. Pod heslom „nezávislého myslenia” boli študenti vedení k tomu, aby sa stavali proti
        tradíciám a proti autoritám a boli povzbudzovaní k tomu, aby opovrhovali spoločnosťou a staršími občanmi. Sú im
        vštepované ľavicové príbehy o dejinách a spoločenských štúdiách a sú ponorení do vulgárnej zábavy.</p>
      <p>V krajinách ovládaných komunistickými režimami sú deti už od materskej školy aktívne indoktrinované
        marxistickými politickými teóriami. Tradičnú kultúru a vieru úplne nahrádza ateistická komunistická kultúra
        nenávisti a boja. Tým, že vyrastajú v prostredí neustáleho vymývania mozgov, sa takéto deti vychované v
        komunistických krajinách naučia myslieť pomocou rovnakej pokrútené logiky ako komunistická strana.</p>
      <p>Excentrické a odchýlené trendy napĺňajú dnešnú konzumnú spoločnosť, zatiaľ čo starodávne remeslá vymizli.
        Tradičné štandardy remeselných zručností a obchodnej etiky sa stratili. Odcudzení od tradičnej kultúry a
        tradičného spôsobu života sa ľudia vzďaľujú od Nebies. Dnešná spoločnosť uctieva sexuálnu slobodu a zvrátenosť.
        Mládež je závislá od videohier, sociálnych médií a pornografie.</p>
      <p>Aj umenie sa dostalo pod neúprosnú paľbu. Nebesá odovzdali človeku poctivé, tradičné umenia, ktoré sa najprv
        objavili v chrámoch, kostoloch a ďalších miestach uctievania. Pravé umenie prináša pravdivosť, láskavosť, krásu
        a čestnosť, čím pomáha ľudstvu udržiavať tradičnú morálnu kultúru. Teraz sály s umením zaplnila kopa odpadkov.
        Temné, pochmúrne maľby zachytávajú scény z podsvetia. Impresionizmus, surrealizmus a iné groteskné štýly
        nahradili vynikajúce diela staroveku a renesancie. V literatúre boli staroveké klasické diela, ktoré
        stelesňovali múdrosť celej civilizácie, nahradené povrchnými a spletitými modernými dielami.</p>
      <p>V hudbe, kedysi vytváranej a hranej na počesť božskej slávy, dnes dominuje obscénnosť a oplzlý hluk. Popkultúra
        je plná démonických tém oslavujúcich násilie a drogy. Celebrity so stovkami miliónov fanúšikov propagujú
        degenerovaný a nemorálny životný štýl. Vznešené, ušľachtilé a čisté je zosmiešňované, zatiaľ čo vulgárne a
        hanebné je chválené.</p>
      <h2>3. Komunizmus na Východe a&nbsp;na Západe</h2>
      <p>Pre komunizmus je charakteristická ateistická filozofia boja a svoju politickú organizáciu a ideológiu
        odvodzuje od organizácie a ideológie gangov a kultov. Na Východe komunizmus reprezentujú totalitné režimy a
        krutí vodcovia ako Vladimir Lenin, Josef Stalin, Mao Ce-tung, Ťiang Ce-min a ich nasledovníci. Situácia na
        Západe je zložitejšia, keďže tam mocenské elity vo vláde, podnikaní, akademických kruhoch a náboženstve
        realizujú svoje intrigy na podkopanie spoločnosti.</p>
      <p>Do kedysi pravoverných náboženstiev bolo vnesené svetské náboženstvo socializmu. Či už z dôvodu politickej
        kontroly komunistickými režimami alebo scestného výkladu neveriaceho duchovenstva boli tradičné učenia a
        posvätné písma zmenené. Teológia oslobodenia vniesla do inak spravodlivej viery marxistickú ideológiu a triedny
        boj, pričom medzi duchovenstvom sa rozšírila mravná zvrátenosť. Z tohto dôvodu mnoho veriacich stratilo nádej v
        cirkev a vzdali sa viery v Božiu spásu.</p>
      <p>Spolu s náboženstvom a národom patrí rodina k základným kameňom ľudskej civilizácie. Je to dôležitá bašta
        morálky a tradície a slúži ako prostriedok na odovzdávanie kultúry z generácie na generáciu. Ľavicové hnutia po
        celom svete podporujú feminizmus, sexuálne oslobodenie a homosexualitu, čím podkopávajú tradičnú štruktúru
        rodiny a harmonické role muža a ženy. Tieto ideologické trendy legitimizujú a povzbudzujú promiskuitu,
        cudzoložstvo, svojvoľný rozvod a potrat, narušujúc tak zdravé vzťahy a základné normy pre ľudskú existenciu.
        Zničenie rodiny je kľúčovou súčasťou diablovho plánu na zničenie ľudstva.</p>
      <h3>Totalitarizmus na Východe</h3>
      <p>Rusko bolo svojou porážkou v prvej svetovej vojne oslabené, čo viedlo cára k abdikácii. Túto situáciu využili
        komunistickí revolucionári, ktorí v roku 1917 spustili októbrovú revolúciu, aby zvrhli konštitučnú vládu. Po
        ničivej občianskej vojne komunisti založili prvý socialistický režim na svete – Sovietsky zväz – a využili svoje
        obrovské zdroje na export revolúcie do zahraničia prostredníctvom komunistickej internacionály.</p>
      <p>Čínska komunistická strana (ČKS) bola založená v roku 1921 s priamou podporou Sovietov. V nasledujúcich
        desaťročiach komunisti viedli násilnú a zradnú rebéliu proti Čínskej republike. ČKS veľmi ťažila z japonskej
        invázie v druhej svetovej vojne a pokračovala v boji proti vládnucej nacionalistickej strane počas vojny a po
        nej. V roku 1949 komunisti prevzali celú pevninskú Čínu a vytvorili totalitnú Ľudovú republiku.</p>
      <p>Sovietska i čínska komunistická strana v čase mieru bezohľadne zabila desiatky miliónov svojich vlastných
        občanov. S cieľom presadiť svoju škodlivú marxistickú ideológiu spustila ČKS bezprecedentnú kultúrnu revolúciu,
        vyhlasujúc vojnu päťtisícročnej tradičnej kultúre Číny a jej nádhernej starobylej civilizácii.</p>
      <p>Začiatkom 80. rokov 20. storočia ČKS zaviedla hospodárske reformy, aby odvrátila svoj vlastný kolaps, zatiaľ čo
        politickú sféru udržiavala pod prísnou totalitnou kontrolou. Strana sa dodnes pevne snaží udržať sa pri moci
        prostredníctvom kampaní útlaku, ako sú zásahy proti demokratickému hnutiu a prenasledovanie Falun Gongu.</p>
      <h3>Infiltrácia na Západe</h3>
      <p>Čínsky cisársky dvor, západné božské právo kráľov a americký systém kontroly a vyváženia sú formy vlády, ktoré
        Nebesá stanovili pre ľudí podľa ich jedinečných kultúr a prostredí. Hoci komunistické revolúcie nedokázali v
        západných krajinách prevziať moc, duch komunizmu napriek tomu vytvoril skrytú kontrolu nad slobodným svetom
        prostredníctvom úkladov a infiltrácie. Aj bez násilnej revolúcie sa západné krajiny vzdali spravodlivých metód
        riadenia štátnych záležitostí a zoširoka prijali rôzne prvky komunistického systému, ako sú vysoké dane, drahé
        sociálne štáty, nadmerná a samoúčelná byrokracia a politická korektnosť.</p>
      <p>Zákon, pôvodne založený na náboženskej morálke a Božích prikázaniach, bol zmenený tak, aby vyhovoval
        deviantnému chápaniu etiky a slobody. V komunistických krajinách na Východe musia zákony slúžiť režimu. Na
        Západe je zákon interpretovaný ľavicovou ideológiou a upravený tak, aby vykorenil morálne poňatie dobra a zla.
        Prijímajú sa právne predpisy, ktoré preukazujú zhovievavosť voči závažným trestným činom, podporujú
        cudzoložstvo, podkopávajú rodinu a obmedzujú práva čestných občanov prostredníctvom prísnych nariadení.</p>
      <p>Vlády aj občania boli vtiahnutí do kultúry nadmernej spotreby. Finančné elity opustili tradičnú múdrosť, ktorá
        riadila udržateľnú ekonomiku, a nahradili zlatý štandard fluktuujúcou menou s&nbsp;núteným obehom. Banky a štát
        podporujú hromadenie nekonečného dlhu, čo vedie k večným hospodárskym krízam a narušeniu národnej
        suverenity.</p>
      <p>Komunistický duch použil globalizáciu ako nástroj na postupné narušenie suverenity jednotlivých národov
        prostredníctvom organizácií ako Liga národov a OSN. Tieto inštitúcie propagujú utopické riešenia medzinárodných
        sporov a konfliktov, pričom v skutočnosti tieto globálne autority slúžia hanebnej agende. Organizácia Spojených
        národov, napriek tomu, že väčšinu svojich finančných prostriedkov získala od západných demokracií, sa čoraz viac
        dostáva pod vplyv komunistických režimov ako je Čínska ľudová republika. Medzinárodné organizácie sa používajú
        na šírenie ľavicovej ideológie a na podkopávanie legitímnych národných záujmov. Konečným cieľom je dostať celý
        svet pod jeden totalitný režim s prísnou kontrolou politiky, ideológie a obyvateľstva.</p>
      <p>Hlavne vďaka pomoci masmédií získali ľavicové a iné zhubné agendy v hlavnom prúde spoločnosti v západných
        krajinách značne veľký vplyv. V krajinách riadených komunistickými režimami sú všetky mediálne výstupy predmetom
        štátnej cenzúry, alebo sú priamo kontrolované komunistickou stranou. V iných krajinách sa média dostali pod
        politický vplyv a pod vplyv finančníkov. Poctivé písanie správ je pochované pod lavínou senzacionalizmu,
        politického mávania cnosťou a&nbsp;výslovne falošných správ.</p>
      <h2>4. Rozpad spoločnosti</h2>
      <p>Aby duch komunizmu zvrhol tradičnú ľudskú spoločnosť, rozpútal v globálnom rozsahu sociálne hnutia, masové
        prisťahovalectvo a rôzne prevraty. Tento ohromujúci proces prebieha už niekoľko storočí.</p>
      <h3>Vojna a revolúcia</h3>
      <p>Uchopenie politickej moci je jedným z kľúčových krokov v komunistickom pláne zničiť ľudstvo. Karl Marx v
        zhrnutí ponaučení získaných počas parížskej komúny napísal, že robotnícka trieda musí zvrhnúť pôvodný vládny
        aparát a nahradiť ho vlastným štátom. Moc je vždy jadrom marxistickej politickej teórie.</p>
      <p>Vojna je jedným z najefektívnejších nástrojov ducha komunizmu, pretože dokáže narušiť starý medzinárodný
        poriadok, zničiť bašty tradície a urýchliť rozvoj komunistickej ideológie. Mnoho vojen bolo rozpútaných pod
        démonickým vplyvom. Prvá svetová vojna priniesla kolaps niekoľkých európskych ríš, najmä cárskeho Ruska. To
        vydláždilo cestu boľševickej revolúcii.</p>
      <p>Druhá svetová vojna poskytla podmienky ČKS k tomu, aby sa chopila moci, a Sovietskemu zväzu, aby napadol
        východnú Európu a ustanovil tak povojnový socialistický tábor.</p>
      <p>Vojna tiež spôsobila zmätky pri dekolonizácii, ktorú sovietske a čínske komunistické režimy využili na podporu
        celosvetového komunistického hnutia. Národné oslobodenecké hnutia vtiahli mnoho krajín po celej Ázii, Afrike a
        Latinskej Amerike do autoritárskeho socializmu.</p>
      <p>Podnecovanie k revolúcii možno rozdeliť do nasledujúcich krokov:</p>
      <p>1. Podnecovanie nenávisti a sporov medzi ľuďmi.</p>
      <p>2. Podvedenie verejnosti klamstvami a vytvorenie jednotného revolučného frontu.</p>
      <p>3. Porazenie síl odporu jednu za druhou.</p>
      <p>4. Použitie násilia, aby sa vytvorila atmosféra teroru a chaosu.</p>
      <p>5. Uskutočnenie prevratu na získanie moci.</p>
      <p>6. Potlačenie reakcionárov.</p>
      <p>7. Vybudovanie a udržovanie nového poriadku za použitia teroru revolúcie.</p>
      <p>Komunistické krajiny sa pokúšali spustiť svetovú revolúciu prostredníctvom komunistickej internacionály,
        vyvážali revolučný aktivizmus a vytvárali nepokoje v nekomunistických krajinách tým, že podporovali miestnych
        ľavičiarov.</p>
      <p>Komunizmus využíva rozdiely medzi ľuďmi a smeruje hnev jednotlivcov do kolektívnej nenávisti. Komunistické
        revolúcie sú úspešné vďaka teroru a komunistické režimy implementujú politiku štátneho terorizmu. Väčšina
        teroristických hnutí sa inšpirovala leninským organizačným modelom a sovietski a čínski komunisti podporovali
        teroristické skupiny ako akési ich komandá, ktoré poškodzujú verejný poriadok a&nbsp;rozdeľujú spoločnosť vo
        zvyšku sveta.</p>
      <p>Iracionalita, s akou teroristi vyberajú rukojemníkov a zabíjajú nevinných ľudí, vytvára atmosféru bezmocnosti.
        Ľudia, ktorí sú vystavení nechcenému násiliu, sa stávajú viac antisociálnymi, depresívnymi, paranoidnými a
        cynickými. To všetko poškodzuje verejný poriadok a štiepi spoločnosť, pomáha vytvárať podmienky potrebné na to,
        aby sa komunizmus chopil moci.</p>
      <h3>Ekonomické a sociálne krízy</h3>
      <p>Socialistické a komunistické hnutia na celom svete využili ekonomické nepokoje k tomu, aby sa dostali do
        vplyvných pozícií, s konečným cieľom zvrhnúť existujúci spoločenský poriadok.</p>
      <p>Ekonomická kríza môže byť umelo vytvorená a použitá ako prostriedok k podpore revolúcie alebo k vykresleniu
        socialistických hnutí ako spasiteľských. Keď politici v demokratických krajinách beznádejne hľadajú riešenia
        ťažkých situácií, uzatvárajú faustovské dohody, čím svoje krajiny postupne vedú smerom k veľkej vláde a
        socializmu s vysokými daňami. Ako napísal Saul Alinsky vo svojej knihe <em>Pravidlá pre radikálov</em>:
        „Skutočnou akciou je reakcia nepriateľa.”</p>
      <p>Veľký hospodársky pokles v 30. rokoch bol kľúčovým okamihom, kedy sa Európa a Spojené štáty vydali smerom k
        veľkej vláde a rozsiahlemu intervencionizmu. Finančná kríza v roku 2008 pomohla ďalej vychýliť rameno váh smerom
        k rozširovaniu ľavicovej politiky.</p>
      <p>So vzostupom industrializácie a globalizácie prišla masová migrácia, najprv z vidieka do miest, potom cez
        hranice a kontinenty. Aj keď sa ľudia už od staroveku sťahovali z jedného miesta na druhé, rýchle domáce a
        medzinárodné masové pohyby ľudí, ktoré môžeme vidieť v dnešnej dobe, sú výsledkom manipulácie ducha
        komunizmu.</p>
      <p>Masová imigrácia rozbíja národnú identitu, hranice, suverenitu, kultúrne tradície a spoločenskú súdržnosť. Keď
        sú masy ľudí zbavené svojej tradičnej identity, ľahšie sa nechajú pohltiť vlnami modernosti. Pre imigrantov
        žijúcich v neznámom prostredí je ťažké, aby si zaistili obživu, nieto aby sa hlbšie zapájali do politických
        procesov alebo kultúrnych tradícií hostiteľských krajín. Noví prisťahovalci sú často ľahko naverbovaní a
        predstavujú voľné hlasy pre ľavicové strany a spoločenské hnutia. Medzitým imigrácia vytvára vhodné podmienky
        pre zasievanie rasovej a náboženskej nevraživosti.</p>
      <p>Komunizmus využíva rôzne spoločenské trendy k tomu, aby rozdúchaval vášne ľudí a eskaloval konflikty,
        mobilizoval hnutia na destabilizovanie spoločnosti, napádal svojich politických oponentov, ovládol diskusiu
        a&nbsp;zdanlivo získal morálnu prevahu. Príklady týchto trendov zahŕňajú protivojnové hnutia a
        environmentalizmus, ktoré komunisti zneužili pre svoje vlastné ciele.</p>
      <h2>5. Stratégia rozdeľuj a&nbsp;panuj</h2>
      <p>Duch komunizmu s ľuďmi zaobchádza podľa ich rôznych pováh a motivácií. Niektorým berie životy a&nbsp;u ďalších
        využíva ich chamtivosť. Môže využiť ľudský idealizmus a emócie, čím indoktrinuje jednotlivcov, aby slúžili ako
        pešiaci revolúcie a vzbury.</p>
      <h3>Vyhladenie disentu</h3>
      <p>Niektorí ľudia sú múdrejší a vnímavejší než druhí. Niektorí majú k duchovným veciam bližšie a nenechajú sa
        ľahko oklamať diablovými trikmi. V krajinách, ktoré zažili dlhú a bohatú históriu, je ťažké ľudí prinútiť k
        tomu, aby sa stotožnili s podvodom. Duch komunizmu neváha fyzicky zlikvidovať členov spoločnosti s vlastným
        názorom, ktorí sú schopní uvidieť jeho konšpiráciu a sú natoľko statoční, že sú mu schopní vzdorovať. Aby sa s
        nimi vysporiadal, organizuje politické kampane, náboženské perzekúcie, vykonštruované súdne procesy a nájomné
        vraždy.</p>
      <p>V Číne, ktorá sa môže pochváliť piatimi tisíckami rokov nebesami inšpirovanej civilizácie, mohla ČKS rozbiť
        kultúrny poriadok iba prostredníctvom spustenia niekoľkých politických kampaní, počas ktorých boli zabité
        desiatky miliónov ľudí. Vyvraždením spoločenských elít, ktoré slúžili ako ochrancovia tradičnej čínskej kultúry,
        rozložila kultúrny poriadok spoločnosti. Zvláštna pozornosť bola venovaná vraždeniu učencov, šľachty a
        duchovných ľudí, ktorí slúžili ako ochrancovia tradičnej čínskej kultúry.</p>
      <p>Aby sa elity zo všetkých národov a odvetví vydali na démonickú cestu, duch komunizmu ich získal na svoju stranu
        napĺňaním ich záujmov a tak, že im poskytol moc podľa toho, ako tesne nasledujú jeho plány. Tým, ktorí sa
        usilujú o slávu a vplyv, zaistí duch dobrú povesť a právomoci. Chamtivým zariadi zisk. Arogantným nafukuje ego a
        nevedomých udržuje v blaženosti. Talentovaní sú zvádzaní vedou, materializmom a nespútanou slobodou prejavu.
        Jednotlivcom so vznešenými ambíciami a dobrými úmyslami premení ich ideály na samochválu a umožní im zažiť
        hrejivý pocit z toho, že sú prezidentmi, premiérmi, členmi think-tankov, stratégmi, riadiacimi pracovníkmi,
        dôležitými bankármi, profesormi, expertmi, laureátmi Nobelovej ceny a podobne. Zaistí im význačné spoločenské
        postavenie, politický vplyv a značné bohatstvo. Hneď ako tieto osobnosti získajú v spoločnosti dobré meno, duch
        ich použije - každého na základe jeho vlastných okolností. Všetci títo ľudia sa potom stanú jeho nevedomými
        agentmi a slovami Lenina „užitočnými idiotmi”.</p>
      <h3>Ohlupovanie más</h3>
      <p>Komunistická ideológia manipuluje verejnú mienku falošnými príbehmi, klame ľudí svojim pokriveným vzdelávacím
        systémom a ovláda masmédiá. Využíva ľudskú túžbu po bezpečí a&nbsp;povrchné záujmy, aby tak prinútila ľudí
        starať sa len o okamžité záujmy, vulgárnu zábavu, súťaživé športy, spoločenské klebety a oddávanie sa erotickým
        a telesným túžbam. Zároveň s tým politici nahrávajú všeobecnému vkusu, aby pripravili ľudí o ich bdelosť a
        úsudok a získali si tak voliča.</p>
      <p>V totalitných komunistických krajinách ľuďom nikdy nie je dovolené akokoľvek zasahovať do politiky. V
        demokratických krajinách je pozornosť tých, ktorým leží na srdci verejné dobro, obrátená smerom k nepodstatným
        záležitostiam (ako sú práva transsexuálov), čo odráža slávnu lesť z dávnej čínskej vojenskej histórie „naoko
        opravovať mosty, pričom v&nbsp;skutočnosti sa postupuje po skrytej ceste”. Virálne správy, spoločenské senzácie
        a dokonca aj teroristické útoky a vojny sú naplánované tak, aby zakryli konečný cieľ komunizmu.</p>
      <p>Verejnosti sú vštepované moderné trendy myslenia a je mobilizovaná k tomu, aby prehlušila malú skupinu ľudí,
        ktorá sa vytrvalo drží tradícií. Intelektuáli po celom svete ostro kritizujú ľudovú kultúru, čo len podporuje
        vytváranie úzkoprsých predsudkov u ich nerozhodného publika. Koncepty kritického a tvorivého myslenia sú
        zneužívané na to, aby postavili mladšiu generáciu proti autoritám, a v dôsledku toho potom mladí ľudia nie sú
        schopní prijať vedomosti a múdrosť tradičnej kultúry.</p>
      <p>V komunistických krajinách bola po vyvraždení nositeľov tradičnej kultúry indoktrinovaná väčšina obyvateľstva k
        tomu, aby sa zúčastnila na revolúcii. Keď sa komunistická strana chopila moci v Číne, trvalo dva a pol
        desaťročia, aby vychovala novú generáciu „vlčích mláďat” čo je čínsky pojem označujúci tých, ktorí vyrastali v
        komunizme a bolo im vštepované, aby nenávideli a zabíjali triednych nepriateľov. Boli povzbudzovaní k tomu, aby
        bez rozmyslu bojovali, ničili, kradli a pálili.</p>
      <p>Počas kultúrnej revolúcie neplnoleté dievčatá bez váhania ubili svoje učiteľky k smrti. V súčasnosti
        internetoví trollovia, známi ako „päťdesiatcentová armáda”, aktívne pracujú v rôznych sociálnych médiách v Číne,
        neustále píšu o bití a zabíjaní, s typickými vetami ako: „Zoberme si späť ostrovy Tiao-jü, aj keby potom mala
        byť Čína vyprahnutá” alebo „Radšej nech je Čína posiata hrobmi, než aby prežil jediný Japonec”. Ich vražedné
        naladenie je aktívne pestované Čínskou komunistickou stranou.</p>
      <p>Na Západe komunistické strany hrdo nasledujú skúsenosť francúzskej revolúcie a Parížskej komúny. Každá
        revolúcia a povstanie boli iniciované bandou zločincov, ktorí nemali žiadne zábrany, ostych ani súcit.</p>
      <h3>Trieštenie spoločnosti</h3>
      <p>Staršia generácia je dnes znevažovaná a odstraňovaná zo spoločnosti, a to v zrýchlenom tempe. Zatiaľ čo mladí
        ľudia majú čím ďalej viac práv, politickej moci a privilégií, starší ľudia strácajú svoju pozíciu autority a
        prestíže, čím sa urýchľuje rozchod ľudstva s tradíciami. Súčasná literatúra, umenie a populárna kultúra sú
        všetky prispôsobené vkusu a hodnotám mladých ľudí, ktorí sú pod tlakom toho, aby neustále sledovali posledné
        trendy v móde, inak budú svojimi rovesníkmi vylúčení zo spoločnosti.</p>
      <p>Rýchly rozvoj vedy a technológie spôsobuje, že starší ľudia nie sú schopní udržať krok a prispôsobiť sa
        masívnym spoločenským zmenám, ktoré sa v dôsledku toho odohrávajú. Premena mestských a vidieckych oblastí spolu
        s obrovskou mierou prisťahovalectva prispievajú k tomu, že staršia generácia cíti, že súčasnosti nerozumie.
        Trápenie a bezmocnosť ich samoty sú posilňované realitou moderného života, keď mladí ľudia neustále súťažia a
        nemajú veľa času venovať sa svojim rodičom a starým rodičom.</p>
      <p>V tradičnej ľudskej spoločnosti si ľudia navzájom pomáhajú. Keď sa dostanú do sporov, majú svoju vieru,
        morálku, zákony a ľudové tradície, ktoré im poskytujú riešenia a spoluprácu. Nie je možné privodiť kolaps takto
        organicky previazanej spoločnosti počas krátkeho časového obdobia. Najprv treba privodiť rozpad spoločnosti do
        malých atomizovaných skupiniek, čím sa pretrhne tradičné spojenie medzi jednotlivcami a&nbsp;ľudia sa od seba
        odcudzia.</p>
      <p>Takmer každý mysliteľný štandard bol použitý, aby spoločnosť rozdelil do proti sebe stojacich skupín a zasial
        medzi nich nenávisť a boj. Spoločenská trieda, pohlavie, rasa, etnická a náboženská príslušnosť, to všetko môže
        slúžiť ako dôvod k rozdeleniu spoločnosti. Komunizmus a iné ideológie ovplyvnené duchom komunizmu zväčšujú
        nevraživosť medzi buržoáziou a robotníckou triedou, riadiacimi pracovníkmi a podriadenými, pokrokovými ľuďmi a
        „spiatočníkmi”, liberálmi a konzervatívcami – zatiaľ čo medzi sebou ľudia bojujú, vláda neustále zväčšuje svoju
        moc s cieľom vybudovať nezastaviteľný totalitný štát.</p>
      <h2>6. Klam a obrana</h2>
      <p>Duch komunizmu sa šikovne skrýva. Je ťažké pochopiť rozsah jeho podvodu, ktorý vytvoril prostredníctvom celého
        radu klamstiev, a to od skrytých až k tým zjavným.</p>
      <p>Duch komunizmu vykonáva svoje najdiabolskejšie intrigy za denného svetla, zatiaľ čo ich označuje ako rozumné,
        opodstatnené a legálne. Sú tak všadeprítomné, že je ťažké odhaliť pravú podstatu týchto intríg. Z&nbsp;času na
        čas duch komunizmu odhalí určité aspekty svojej agendy, ale len preto, aby odvrátil pozornosť od ešte väčšieho
        sprisahania. Napríklad počas studenej vojny bol svet rozdelený na dva vojenské a politické tábory. Hoci sa ich
        spoločenské systémy javili ako diametrálne odlišné, na oboch stranách sa v rôznych formách odohrával rovnaký
        zlovoľný proces. Mnoho revizionistických komunistov západného štýlu, socialistov, fabianistov, liberálov a
        progresívcov síce verejne odmietlo sovietsky a čínsky model, ale ich úsilie spoločnosť zaviedlo na cestu smerom
        k sociálnemu usporiadaniu, ktoré sa nijako nelíši od tých v Sovietskom zväze a v Číne. Duch komunizmu teda v
        podstate použil totalitný Východ na to, aby odviedol pozornosť ľudí od svojej aktívnej infiltrácie Západu.</p>
      <p>Tí, ktorí sa odvážia komunistického ducha odhaliť, sú onálepkovaní ako „zástancovia konšpiračných teórií”,
        „extrémisti”, „krajná pravica”, „alternatívna pravica”, „sexisti”, „rasisti”, „vojnoví štváči”, „šovinisti”,
        „nacisti”, „fašisti” a ďalšími urážlivými termínmi, ktoré majú za úlohu izolovať ich a ponížiť ich pred
        akademickou obcou a širokou verejnosťou. Pretože sú títo jedinci takto vylúčení, stanú sa objektami posmechu a
        osobami, pred ktorými sa treba mať na pozore. Preto ich myšlienky nezískajú žiadne publikum a ich prítomnosť
        nemá na verejnosť žiadny vplyv.</p>
      <p>Ľudia sú zároveň navádzaní, aby boli proti a aby nenávideli určité etniká, skupiny a jednotlivcov, čím sa takto
        odvádza pozornosť od fundamentálneho zla, ktorým je komunistický duch.</p>
      <p>Je nemožné, aby sa celé ľudstvo nechalo podviesť klamstvami komunistického ducha. Ale komunizmu sa už podarilo
        dostať pomocou jeho nespočetných premien pod svoj vplyv väčšinu spoločnosti a jej vedúcich predstaviteľov z
        celého sveta. Nie je prehnané tvrdiť, že duch komunizmu už nášmu svetu vládne. Vo svetle všeobecných stratégií,
        ktoré boli vymenované vyššie, budeme v nasledujúcich kapitolách podrobnejšie skúmať, akým spôsobom sa
        komunistický duch dostal k moci a aké kroky musí ľudstvo spraviť, aby sa vyhlo zničeniu v rukách tohto
        komunistického ducha.</p>
      <Link to={`/kapitola_02`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


